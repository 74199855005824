<template>
  <div class="modal fade modal-bottom show" style="display: block;" aria-modal="true" role="dialog">
    <div class="modal-bg" @click="close"></div>
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">{{ title }}</div>
          <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button" @click="close"></button>
        </div>
        <div class="modal-body">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps , defineEmits} from "vue"

const props = defineProps({
  title: String
})
const emit = defineEmits(["close"])
const close = () => {
  emit("close")
}
</script>

<style scoped>
</style>
