<template>
  <div class="field">
    <div class="input-group">
      <input placeholder="厚さ" class="form-control" type="number" v-model="depth"/>
      <div class="input-group-text">mm</div>
    </div>
  </div>
</template>

<script setup>
import {defineProps, computed} from "vue";
import {FormValue} from "./libs/form_value";

const props = defineProps({
  formValue: FormValue
})

const depth = computed({
  get: () => props.formValue.depth,
  set: (val) => {
    props.formValue.updateAttribute("depth", val)
  },
})
</script>

<style scoped>
</style>
