<template>
  <SelectModalBase @close="close" title="郵送箱を選択">
    <div class="search">
      <input type="number" v-model="searchWord" />
    </div>
    <ul class="values">
      <li v-for="mailbox in filteredMailboxes" :key="mailbox.id" @click="selectItem(mailbox)">
        {{mailbox.space_id}}: {{mailbox.name}}
      </li>
    </ul>
  </SelectModalBase>
</template>

<script setup>
import {defineEmits, defineProps, ref, computed} from "vue"
import SelectModalBase from "./SelectModalBase.vue"
const props = defineProps({
  mailboxes: Array
})
const emit = defineEmits(["close", "select-item"])
const close = () => {
  emit("close")
}
const searchWord = ref("")
const filteredMailboxes = computed(() => {
  if(searchWord.value === "") return props.mailboxes
  return props.mailboxes.filter((mailbox) => {
    return String(mailbox.space_id) === String(searchWord.value)
  })
})
const selectItem = (mailbox) => {
  emit("select-item", mailbox)
  close()
}
</script>

<style scoped>
</style>
