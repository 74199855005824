import $ from 'jquery'

class MailingDocument {
  constructor(args = {}) {
    this.uploadFileId = args["uploadFileId"] || "#upload-file"
    this.pdfPreviewId = args["pdfPreviewId"] || "#pdf-preview"
    this.deleteButtonId = args["deleteButtonId"] || "#delete-btn"
    this.saveButtonId = args["saveButtonId"] || "#save-btn"
  }

  get file() {
    return $(this.uploadFileId)
  }

  get pdfPreview() {
    return $(this.pdfPreviewId)
  }

  get deleteBtn() {
    return $(this.deleteButtonId)
  }

  get saveBtn() {
    return $(this.saveButtonId)
  }

  clickUploadFile() {
    this.file.click()
  }

  changeUploadFile(target) {
    const fileData = target.files[0];
    if (!fileData) return
    const reader = new FileReader();
    reader.onload = () => {
      this.pdfPreview.show()
      this.deleteBtn.show()
      this.saveBtn.show()
      this.pdfPreview.attr("src", reader.result);
    }
    reader.readAsDataURL(fileData);
  }

  deleteUploadFile() {
    this.file.val("")
    this.pdfPreview.hide()
    this.deleteBtn.hide()
    this.saveBtn.hide()
  }
}

window.MailingDocument = MailingDocument
