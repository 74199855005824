<template>
  <div>
    <SelectMailTypeModal
        v-if="isShowSelectMailTypeModal"
        @close="closeSelectMailTypeModal"
        @select-item="selectItem"
        :mail-types="mailTypes"
    />
    <div class="field" @click="openSelectMailTypeModal">
      <div class="select-list">
        <div class="display-value" :class="emptyValueClasses">{{mailTypeLabel}}</div>
        <div v-show="formValue.showError('mailType')" class="error">選択してください</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, defineProps, ref} from "vue"
const props = defineProps({
  formValue: FormValue
})
import SelectMailTypeModal from "./SelectMailTypeModal.vue"
import {FormValue} from "./libs/form_value";
import {mailTypes} from "./libs/mail_types";
const isShowSelectMailTypeModal = ref(false)
const openSelectMailTypeModal = () => {
  isShowSelectMailTypeModal.value = true
}
const closeSelectMailTypeModal = () => {
  isShowSelectMailTypeModal.value = false
}

const selectedItem = computed(() => {
  return props.formValue.mailType
})

const mailTypeLabel = computed(() => {
  if(!selectedItem.value) return "タイプを選択"
  return mailTypes[selectedItem.value]
})

const emptyValueClasses = computed(() => {
  if(!selectedItem.value) return ["value-empty"]
  return []
})

const selectItem = (mailType) => {
  props.formValue.updateAttribute("mailType", mailType)
}
</script>

<style scoped>
</style>
