import { createApp } from 'vue'
import App from './components/regist_mailing_form/app.vue'
const SELECTOR = '#regist-mailing-form';
const mountRegistMailingForm = () => {
  document.addEventListener('DOMContentLoaded', () => {
    if(document.querySelector(SELECTOR)){
      createApp(App).mount(SELECTOR);
    }
  })
}

window.mountRegistMailingForm = mountRegistMailingForm
