<template>
  <div>
    <SelectStoragePlaceModal
        v-if="isShowSelectStoragePlaceModal"
        @close="closeSelectStoragePlaceModal"
        @select-item="selectItem"
        :storage-places="storagePlaces"
    />
    <div class="field" @click="openSelectStoragePlaceModal">
      <div class="select-list">
        <div class="display-value" :class="emptyValueClasses">{{storagePlaceLabel}}</div>
        <div v-show="formValue.showError('storagePlace')" class="error">選択してください</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {defineEmits, defineProps, ref, computed} from "vue"
import SelectStoragePlaceModal from "./SelectStoragePlaceModal.vue"
import {FormValue} from "./libs/form_value"
const isShowSelectStoragePlaceModal = ref(false)
const openSelectStoragePlaceModal = () => {
  isShowSelectStoragePlaceModal.value = true
}
const closeSelectStoragePlaceModal = () => {
  isShowSelectStoragePlaceModal.value = false
}

const props = defineProps({
  storagePlaces: Array,
  formValue: FormValue
})
const emit = defineEmits(["close"])

const selectItem = (storagePlace) => {
  props.formValue.updateAttribute("storagePlace", storagePlace)
}

const selectedItem = computed(() => {
  return props.formValue.storagePlace
})

const storagePlaceLabel = computed(() => {
  if(!selectedItem.value) return "保管場所"
  return selectedItem.value.name
})
const emptyValueClasses = computed(() => {
  if(!selectedItem.value) return ["value-empty"]
  return []
})
</script>

<style scoped>
</style>
