<template>
  <SelectModalBase @close="close" title="配送会社">
    <ul class="values">
      <li
          v-for="deliveryCompany in deliveryCompanies"
          :key="deliveryCompany.id"
          @click="selectItem(deliveryCompany)"
      >
        {{deliveryCompany.id}}: {{deliveryCompany.name}}
      </li>
    </ul>
  </SelectModalBase>
</template>

<script setup>
import {defineEmits, defineProps} from "vue"
import SelectModalBase from "./SelectModalBase.vue"
const props = defineProps({
  deliveryCompanies: Array
})
const emit = defineEmits(["close", "select-item"])
const close = () => {
  emit("close")
}
const selectItem = (val) => {
  emit("select-item", val)
  close()
}
</script>

<style scoped>
</style>
