import {ref} from "vue"
import axios from "axios";
export const mailboxes = ref([])
const API_URL = "/api/v1/mailboxes"
export const fetch = () => {
  axios
    .get(API_URL)
    .then(response => {
      mailboxes.value = response.data
    })
    .catch(err => {
      console.log(err)
    })
}
