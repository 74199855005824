<template>
  <SelectModalBase @close="close" title="タイプを選択">
    <ul class="values">
      <li
          v-for="(value, key) in mailTypes"
          :key="key"
          @click="selectItem(key)"
      >
        {{ value }}
      </li>
    </ul>
  </SelectModalBase>
</template>

<script setup>
import {defineEmits} from "vue"
import SelectModalBase from "./SelectModalBase.vue"
import {mailTypes} from "./libs/mail_types";
const emit = defineEmits(["close", "select-item"])
const close = () => {
  emit("close")
}
const selectItem = (val) => {
  emit("select-item", val)
  close()
}
</script>

<style scoped>
</style>
