<template>
  <SelectModalBase @close="close" title="スキャン可否">
    <ul class="values">
      <li v-for="(value, key) in workerStatuses" :key="key" @click="selectItem(key)">
        {{ value }}
      </li>
    </ul>
  </SelectModalBase>
</template>

<script setup>
import { defineEmits } from "vue"
import SelectModalBase from "./SelectModalBase.vue"
import {workerStatuses} from "./libs/worker_statuses";
const emit = defineEmits(["close", "select-item"])
const close = () => {
  emit("close")
}
const selectItem = (val) => {
  emit("select-item", val)
  close()
}
</script>

<style scoped>
</style>
