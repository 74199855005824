<template>
  <div>
    <div class="mt-4 text-end me-3"><a @click="moveToForm1">郵便情報編集</a></div>
    <div class="fields">
      <div class="field d-flex justify-content-between">
        <div class="label">郵便箱</div>
        <div class="value text-end">
          {{ mailboxLabel }}
          <br>
          {{ autoScanLabel }}
        </div>
      </div>
    </div>
    <div class="fields">
      <div class="field d-flex justify-content-between">
        <div class="label">差出人</div>
        <div class="value">{{ fromLabel }}</div>
      </div>
    </div>
    <div class="fields">
      <div class="field d-flex justify-content-between">
        <div class="label">タイプ</div>
        <div class="value">{{ mailTypeLabel }}</div>
      </div>
      <div class="field d-flex justify-content-between">
        <div class="label">厚さ</div>
        <div class="value">{{ depthLabel }}</div>
      </div>
      <div class="field d-flex justify-content-between">
        <div class="label">重さ</div>
        <div class="value">{{weightLabel }}</div>
      </div>
      <div class="field d-flex justify-content-between">
        <div class="label">スキャン可否</div>
        <div class="value">{{scanEnabledLabel}}</div>
      </div>
    </div>
    <div class="fields">
      <div class="field d-flex justify-content-between">
        <div class="label">配送会社</div>
        <div class="value">{{deliveryCompanyLabel}}</div>
      </div>
    </div>
    <div class="fields">
      <div class="field d-flex justify-content-between">
        <div class="label">保管場所</div>
        <div class="value">{{storagePlaceLabel}}</div>
      </div>
    </div>
    <div class="mt-4 text-end me-3"><a @click="moveToForm2">画像の編集</a></div>
    <div class="images">
      <PreviewImage v-for="(image, idx) in images" :key="`${idx}-${image.name}`" :idx="idx" :image="image"
                    :editable="false"/>
    </div>
    <div class="mt-4 text-end me-3">
      <a class="btn btn-primary" :class="{disabled: !canSubmit}" @click="submit" :disabled="!canSubmit">{{submitLabel}}</a>
    </div>
  </div>
</template>

<script setup>
import {defineEmits, defineProps, computed} from "vue"
import {FormValue} from "./libs/form_value"
import PreviewImage from "./PreviewImage.vue"
import { mailTypes } from "./libs/mail_types"
import { workerStatuses } from "./libs/worker_statuses"
import  {
  confirmFormSupporter
} from "./libs/confirm_form_supporter"
const props = defineProps({
  formValue: FormValue
})
const emit = defineEmits(["next", "select-page"])
const next = () => {
  emit("next")
}

const moveToForm1 = () => {
  emit("select-page", 1)
}

const moveToForm2 = () => {
  emit("select-page", 2)
}

const  {
  images,
  mailboxLabel,
  autoScanLabel,
  mailTypeLabel,
  fromLabel,
  depthLabel,
  weightLabel,
  scanEnabledLabel,
  deliveryCompanyLabel,
  storagePlaceLabel,
  canSubmit,
  submitLabel
} = confirmFormSupporter(props.formValue)

const submit = () => {
  props.formValue.submit(next)
}

</script>

<style scoped>
</style>
