<template>
  <div class="field">
    <input placeholder="差出人を入力" class="form-control" type="text" v-model="from"/>
    <div v-show="formValue.showError('from')" class="error">入力してください</div>
  </div>
</template>

<script setup>
import {defineProps, computed} from "vue";
import {FormValue} from "./libs/form_value";

const props = defineProps({
  formValue: FormValue
})

const from = computed({
  get: () => props.formValue.from,
  set: (val) => {
    props.formValue.updateAttribute("from", val)
  },
})
</script>

<style scoped>
</style>
