<template>
  <div class="head">
    <div class="title">
      <h1> {{ title }}</h1>
      <a class="close" @click="close">x</a>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue"

const props = defineProps({
  title: String
})

const emit = defineEmits(["close"])
const close = () => {
  emit("close")
}
</script>

<style scoped>
</style>
