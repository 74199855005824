import $ from 'jquery'
import * as bootstrap from 'bootstrap'
import axios from 'axios'

const API_URL_BASE = "/api/v1/receive_mailing"

class ReceiveMailings {
  constructor(args = {}) {
    this.searchingMailbox = false
    this.searchingStoragePlace = false
    this.showingModal = false

    const self = this
    $('.modal').each(function() {
      this.addEventListener('hidden.bs.modal', (e) => {
        // 親要素のonclickイベントが作動してopenList()が呼ばれるのを防ぐ
        e.stopPropagation()
        self.showingModal = false
      })
    })
  }

  validate() {
    let isValid = true

    if($('#mailing_mailbox_id option:selected').val().length < 1) {
      $('#mailing_mailbox_id').siblings('.error').show()
      isValid  = false
    } else {
      $('#mailing_mailbox_id').siblings('.error').hide()
    }

    if($('#mailing_from').val().length < 1) {
      $('#mailing_from').siblings('.error').show()
      isValid  = false
    } else {
      $('#mailing_from').siblings('.error').hide()
    }

    if($('#mailing_mail_type option:selected').val().length < 1) {
      $('#mailing_mail_type').siblings('.error').show()
      isValid  = false
    } else {
      $('#mailing_mail_type').siblings('.error').hide()
    }

    if($('#mailing_scannable option:selected').val().length < 1) {
      $('#mailing_scannable').siblings('.error').show()
      isValid  = false
    } else {
      $('#mailing_scannable').siblings('.error').hide()
    }

    if($('#mailing_delivery_company_id option:selected').val().length < 1) {
      $('#mailing_delivery_company_id').siblings('.error').show()
      isValid  = false
    } else {
      $('#mailing_delivery_company_id').siblings('.error').hide()
    }

    if($('#mailing_storage_place_id option:selected').val().length < 1) {
      $('#mailing_storage_place_id').siblings('.error').show()
      isValid  = false
    } else {
      $('#mailing_storage_place_id').siblings('.error').hide()
    }

    return isValid
  }


  // 郵便箱選択時に動的に号室、スキャン設定の表示切り替え
  selectedMailbox(el) {
    const mailboxInfo = $('#mailbox-info')
    if(el.value.length > 0) {
      const url = this.mailboxUrl(el.value)
      axios
          .get(url)
          .then(response => {
            const data = response.data
            mailboxInfo.find('.room-no').text(this.roomNoText(data.space.room_no)).removeClass('value-empty')
            mailboxInfo.find('.auto-scan').text(this.autoScanText(data.auto_scan)).removeClass('value-empty')
          })
          .catch(err => {
            console.log(err)
          })
    } else {
      mailboxInfo.find('.room-no').text('号室').addClass('value-empty')
      mailboxInfo.find('.auto-scan').text('スキャン設定').addClass('value-empty')
    }
  }

  // セレクトボックスのモーダルを開く
  openList(el) {
    if(this.showingModal) {
      return
    }

    this.showingModal = true
    const selectList = $(el).find('.select-list')
    const modal = new bootstrap.Modal(selectList.find('.modal').get(0))
    modal.show()
  }

  // 選択した値を反映して、モーダルを閉じる
  selectedList(e) {
    const el = $(e.target)
    $(el).siblings().removeClass('selected')
    el.addClass('selected')

    const selectedText = el.text()
    const selectedValue = el.attr('data-value')
    const selectList = el.closest('.select-list')
    selectList.find('.display-value').text(selectedText).removeClass('value-empty')
    selectList.find('select').val(selectedValue)
    selectList.find('select').trigger('change')

    const modal = bootstrap.Modal.getInstance(selectList.find('.modal').get(0))
    modal.hide()
  }

  mailboxUrl(id) {
    return `${API_URL_BASE}/mailboxes/${id}`
  }

  roomNoText(roomNo) {
    return `${roomNo}号`
  }

  autoScanText(autoScan) {
    return autoScan ? '自動スキャン ON' : '自動スキャン OFF'
  }

  searchMailboxUrl() {
    return `${API_URL_BASE}/mailboxes/search`
  }

  // 郵便箱検索
  searchMailbox(el) {
    if(this.searchingMailbox) {
      return
    }

    this.searchingMailbox = true

    let searchText = $(el).val()
    axios
      .get(this.searchMailboxUrl(), { params: { text: searchText } })
      .then(response => {
        const data = response.data

        this.updateModalValueList(el, data)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        this.searchingMailbox = false
      })
  }

  searchStoragePlaceUrl() {
    return `${API_URL_BASE}/storage_places/search`
  }

  // 郵便箱検索
  searchStoragePlace(el) {
    if(this.searchingStoragePlace) {
      return
    }

    this.searchingStoragePlace = true

    let searchText = $(el).val()
    axios
      .get(this.searchStoragePlaceUrl(), { params: { text: searchText } })
      .then(response => {
        const data = response.data

        this.updateModalValueList(el, data)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        this.searchingStoragePlace = false
      })
  }

  // 検索結果をもとにセレクトの値の表示を更新する
  updateModalValueList(el, data) {
    const valueList = $(el).closest('.modal-body').find('.values')

    const selectedValue = valueList.closest('.select-list').find('select').val()

    // リストを一旦空にする
    valueList.empty()

    const self = this

    // 検索結果の値でリストを作り直して追加する
    data.forEach((row) => {
      const li = $('<li>')

      li.text(`${row.id}: ${row.name}`)
      li.attr('data-value', row.id)
      li.attr('onclick', "receiveMailings.selectedList(event)")
      li.on('click', function() {
        self.selectedList(this)
      })

      if(selectedValue == row.id) {
        li.addClass('selected')
      }

      valueList.append(li)
    })
  }
}

window.ReceiveMailings = ReceiveMailings
