import $ from 'jquery'
import axios from 'axios'

const API_URL_BASE = "/api/v1/admins/mailings/:mailing_id/mailing_documents"

class ReceiveMailingDocuments {
  constructor(args = {}) {
    const env = $('#env').val()
    if(env == 'production') {
      this.apiDomain = 'http://api.fuu.to'
    } else if(env == 'staging') {
      this.apiDomain = 'http://staging-api.fuu.to'
    } else {
      // development
      this.apiDomain = 'http://localhost:33000'
    }

    this.mailingId = args['mailingId']
    this.loadImages()
  }

  loadImages() {
    axios
      .get(this.listImageUrl(), { headers: { Authorization: $('#access_token').val() } })
      .then(response => {
        let data = response.data
        // 画像表示
        data.forEach((row) => {
          this.addImage(row)
        })

        this.updateImageNumbers()
      })
      .catch(err => {
        console.log(err)
      })
  }

  selectedImage(el) {
    let params = new FormData()
    params.append('mailing_id', this.mailingId)
    params.append('document_type', 'appearance')
    params.append('body', el.files[0].name)
    params.append('file', el.files[0])
    params.append('from', $('#from').val())

    axios
      .post(this.createImageUrl(), params, { headers: { Authorization: $('#access_token').val() } })
      .then(response => {
        let data = response.data
        // アップロードした画像表示
        if(data.url.length > 0) {
          this.addImage(data)

          this.updateImageNumbers()
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        el.value = ''
      })
  }

  destroyImage(el) {
    let id = $(el).attr('data-id')
    if(id.length < 1) {
      return
    }

    axios
      .delete(this.destroyImageUrl(id), { headers: { Authorization: $('#access_token').val() } })
      .then(response => {
        // アップロードした画像表示削除
        $(el).parent().remove()

        this.updateImageNumbers()
      })
      .catch(err => {
        console.log(err)
      })
  }

  apiUrlBase() {
    return this.apiDomain + API_URL_BASE.replace(':mailing_id', this.mailingId)
  }

  listImageUrl() {
    return this.apiUrlBase() + '?q=' + JSON.stringify({ mailing_id_eq: this.mailingId, document_type_eq: 'appearance' })
  }

  createImageUrl() {
    return this.apiUrlBase()
  }

  destroyImageUrl(id) {
    return `${this.apiUrlBase()}/${id}`
  }

  addImage(data) {
    if(data.url.length < 1) {
      return
    }
    let image = $('.template > .image').clone()
    image.find('img').attr('src', data.url)

    // 削除ボタン
    image.find('.destroy').attr('data-id', data.id)

    $('.images').append(image)
  }

  updateImageNumbers() {
    $('.images').find('.number').each(function(i) {
      $(this).text(i+1)
    })
  }
}

window.ReceiveMailingDocuments = ReceiveMailingDocuments
