<template>
  <div>
    <Header :title="title" @close="reset"/>
    <Form1 v-if="isCurrentPage(1)"
           @next="next"
           :mailboxes="mailboxes"
           :delivery-companies="deliveryCompanies"
           :storage-places="storagePlaces"
           :form-value="formValue" />
    <Form2 v-if="isCurrentPage(2)" @next="next" @prev="prev" :form-value="formValue"/>
    <Form3 v-if="isCurrentPage(3)" @next="next" @select-page="selectPage" :form-value="formValue"/>
    <Form4 v-if="isCurrentPage(4)" :form-value="formValue"/>
  </div>
</template>

<script setup>
import {ref, computed} from "vue"
import Header from "./Header.vue"
import Form1 from "./Form1.vue"
import Form2 from "./Form2.vue"
import Form3 from "./Form3.vue"
import Form4 from "./Form4.vue"
//
import {page, title, selectPage, prev, next, reset, isCurrentPage} from "./libs/settings"
import {mailboxes, fetch as fetchMailboxes} from "./libs/mailboxes"
import {deliveryCompanies, fetch as fetchDeliveryCompanies} from "./libs/delivery_companies"
import {storagePlaces, fetch as fetchStoragePlaces} from "./libs/storage_places"
import { FormValue } from "./libs/form_value"
fetchMailboxes()
fetchDeliveryCompanies()
fetchStoragePlaces()
//
const formValue = new FormValue()
</script>

<style scoped>
</style>
