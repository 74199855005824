import {ref} from "vue"
import axios from "axios";
import {deliveryCompanies} from "./delivery_companies";
import $ from "jquery";

const API_URL = "/api/v1/mailings"

export class FormValue {
  constructor() {
    this.data = ref(
      {
        mailbox: null,
        mailType: null,
        from: null,
        depth: null,
        weight: null,
        workerStatus: null,
        deliveryCompany: null,
        storagePlace: null,
        images: []
      }
    )
    this.mailingData = ref(null)
    this.isCheck = ref(false)
    this.isSubmitting = ref(false)
  }

  // TODO: 簡単なバリデーションなのでフロント側で実行しているが本当はサーバーサイドで行ったものを反映するほうがいい
  valid() {
    this.isCheck.value = true
    const requiredKeys = [
      "mailbox", "mailType", "from", "workerStatus", "deliveryCompany", "storagePlace"
    ]
    const requires = requiredKeys.map((key) => {
      return !!this.data.value[key]
    })
    return !requires.includes(false)
  }

  submit(callback) {
    if (!this.valid()) return
    this.isSubmitting.value = true
    const params = this.generateFormParams()
    axios
      .post(API_URL, params)
      .then(response => {
        this.mailingData.value = response.data
        callback()
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
      this.isSubmitting.value = false
    })
  }

  // MEMO: 画像データ用のファイルも送信したいのでFormDataを作成している
  generateFormParams() {
    const params = new FormData()
    params.append('mailbox_id', this.mailbox?.id)
    // MEMO: scannableはメールボックスの情報を反映させる
    params.append('scannable', this.mailbox?.auto_scan)
    params.append('delivery_company_id', this.deliveryCompany?.id)
    params.append('storage_place_id', this.storagePlace?.id)
    params.append('worker_status', this.workerStatus)
    params.append('mail_type', this.mailType)
    // MEMO: 登録時にはfromを登録したくないないのでtmp_fromとして送信する
    params.append('tmp_from', this.from)
    params.append('depth', this.depth)
    params.append('weight', this.weight)
    //
    this.images.forEach(image => {
      params.append('images[]', image)
    })
    return params
  }

  showError(key) {
    if (!this.isCheck.value) return false
    return !this.data.value[key]
  }

  // MEMO: emitでのバケツリレー方式だとコンポーネント間のデータの変更のやりとりが大変なのでstoreを使わずにクラスを渡す方式を試してみる
  updateAttribute(key, value) {
    this.data.value[key] = value
  }

  updateAttributes(hash) {
    for (let key in hash) {
      this.updateAttribute(key, hash[key])
    }
  }

  addImage(image) {
    this.data.value.images.push(image)
  }

  removeImage(idx) {
    console.log("remove image", idx)
    this.data.value.images = this.data.value.images.filter((_val, tmpIdx) => {
      return tmpIdx !== idx
    })
  }

  get mailbox() {
    return this.data.value.mailbox
  }

  get mailType() {
    return this.data.value.mailType
  }

  get from() {
    return this.data.value.from
  }

  get depth() {
    return this.data.value.depth
  }

  get weight() {
    return this.data.value.weight
  }

  get workerStatus() {
    return this.data.value.workerStatus
  }

  get scanEnabled() {
    return this.workerStatus === "scanning"
  }

  get deliveryCompany() {
    return this.data.value.deliveryCompany
  }

  get storagePlace() {
    return this.data.value.storagePlace
  }

  get images() {
    return this.data.value.images
  }

  get canSubmit() {
    return !this.isSubmitting.value
  }

  get mailing() {
    return this.mailingData.value
  }
}

