<template>
  <div>
    <div class="fields">
      <div class="field d-flex justify-content-between">
        <div class="label">郵便ID</div>
        <div class="value">{{mailing.id}}</div>
      </div>
      <div class="field d-flex justify-content-between">
        <div class="label">CD</div>
        <!-- TODO: CDってどの項目のこと？ -->
        <div class="value"></div>
      </div>
    </div>
    <div class="fields">
      <div class="field d-flex justify-content-between">
        <div class="label">郵便箱</div>
        <div class="value text-end">
          {{mailing.mailbox.name}}
          <br>
          自動スキャン{{mailing.scannable ? "ON": "OFF"}}
        </div>
      </div>
    </div>
    <div class="fields">
      <div class="field d-flex justify-content-between">
        <div class="label">保管場所</div>
        <div class="value">{{mailing.storage_place.name}}</div>
      </div>
    </div>
    <div class="mt-4 text-end me-3">続けて郵便物を登録しますか？</div>
    <div class="mt-4 text-end me-3">
      <a class="btn btn-outline-primary me-3" href="/">終了する</a>
      <a class="btn btn-primary" href="/mailings/new">続けて登録する</a>
    </div>
  </div>
</template>

<script setup>
import {defineProps, computed} from "vue";
import {FormValue} from "./libs/form_value";

const props = defineProps({
  formValue: FormValue
})

const mailing = computed(() => {
  return props.formValue.mailing
})

</script>

<style scoped>
</style>
