import {ref, computed} from "vue"

const MAX_PAGE = 4
export const title = computed(() => {
  if (page.value === 1) return "郵便物の登録"
  if (page.value === 2) return "郵便物の撮影"
  if (page.value === 3) return "郵便物の確認"
  if (page.value === 4) return "シールを作成"
  return ""
})

export const page = ref(1)
export const selectPage = (i) => {
  page.value = i
}
export const prev = () => {
  if (page.value > 1) page.value -= 1
}
export const next = () => {
  if (page.value < MAX_PAGE) page.value += 1
}
export const reset = () => {
  window.location.href = "/mailings/new"
}

export const isCurrentPage = (num) => {
  return page.value === num
}
