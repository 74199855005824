import axios from 'axios';

const API_URL_BASE = "/api/v1/approval_request"

class ApprovalRequests {
  constructor(args = {}) {
    this.isCompleted = args["isCompleted"] || false
    this.isAllDocumentVerified = args["isAllDocumentVerified"] || false
    this.checkboxClassName = args["checkboxClassName"] || "verify-checkbox"
    this.verifyBtnId = args["verifyBtnId"] || "verify-submit"
    this.rejectBtnId = args["rejectBtnId"] || "reject-submit"
    this.transferLetterBtnId = args["transferLetterBtnId"] || "transfer-letter-btn"
  }

  verify() {
    if(!window.confirm("本人確認を完了してよろしいですか？")) return
    this.buttonDisabled(true)
    axios
      .put(this.identificationUrl, {status: "verified"})
      .then(_response => {
        this.verifyBtn.classList.add("d-none");
        this.rejectBtn.classList.add("d-none");
        this.transferLetterBtn.classList.remove("d-none");
        alert("申込者に本人確認完了のメールが送信されました。")
      })
      .catch(err => {
        console.log(err)
        this.buttonDisabled(false)
      });
  }

  reject() {
    if(!window.confirm("本人確認を却下してよろしいですか？")) return
    this.buttonDisabled(true)
    axios
      .put(this.identificationUrl, {status: "reject"})
      .then(_response => {
        this.verifyBtn.classList.add("d-none");
        this.rejectBtn.classList.add("d-none");
        alert("申込者に本人確認却下のメールが送信されました。")
      })
      .catch(err => {
        console.log(err)
        this.buttonDisabled(false)
      });
  }

  buttonDisabled(isDisabled = true) {
    this.verifyBtn.disabled = isDisabled
    this.rejectBtn.disabled = isDisabled
    for (let i = 0, len = this.checkboxes.length | 0; i < len; i++) {
      this.checkboxes[i].disabled = isDisabled
    }
  }

  checkAllDocumentVerified() {
    if (this.isCompleted) return
    let isAllDocumentVerified = true
    for (let i = 0, len = this.checkboxes.length | 0; i < len; i++) {
      if (!this.checkboxes[i].checked) isAllDocumentVerified = false
    }
    this.isAllDocumentVerified = isAllDocumentVerified
    this.verifyBtn.disabled = !isAllDocumentVerified
  }

  toggleDocumentCheckbox(el) {
    el.disabled = true
    const url = this.generateDocumentUrl(el.dataset.id)
    const status = el.checked ? "verified" : "pending"
    axios
      .put(url, {status})
      .then(_response => {
      })
      .catch(err => {
        console.log(err)
      }).finally(
        el.disabled = false
    );
    this.checkAllDocumentVerified()
  }

  get identificationUrl() {
    const id = this.verifyBtn.dataset.id
    return `${API_URL_BASE}/space_identifications/${id}`
  }

  generateDocumentUrl(id) {
    return `${API_URL_BASE}/space_identification_verify_documents/${id}`
  }

  get verifyBtn() {
    return document.getElementById(this.verifyBtnId)
  }

  get rejectBtn() {
    return document.getElementById(this.rejectBtnId)
  }

  get transferLetterBtn() {
    return document.getElementById(this.transferLetterBtnId)
  }

  get checkboxes() {
    return document.getElementsByClassName(this.checkboxClassName)
  }
}

window.ApprovalRequests = ApprovalRequests
