<template>
  <div>
    <div class="fields">
      <SelectMailbox :mailboxes="mailboxes" :form-value="formValue"/>
    </div>
    <div class="fields">
      <InputFrom :form-value="formValue"/>
    </div>
    <div class="fields">
      <SelectMailType :form-value="formValue"/>
      <InputDepth :form-value="formValue"/>
      <InputWeight :form-value="formValue"/>
      <SelectWorkerStatus :form-value="formValue"/>
    </div>
    <div class="fields">
      <SelectDeliveryCompany :delivery-companies="deliveryCompanies" :form-value="formValue"/>
    </div>
    <div class="fields">
      <SelectStoragePlace :storage-places="storagePlaces" :form-value="formValue"/>
    </div>
    <div class="field mt-4 text-end me-3">
      <button class="btn btn-primary" type="button" @click="nextWithValidate">つぎへ</button>
    </div>
  </div>
</template>

<script setup>
import {defineEmits, defineProps} from "vue"
import {FormValue} from "./libs/form_value"
import SelectMailbox from "./SelectMailbox.vue"
import InputFrom from "./InputFrom.vue"
import SelectMailType from "./SelectMailType.vue"
import InputDepth from "./InputDepth.vue"
import InputWeight from "./InputWeight.vue"
import SelectDeliveryCompany from "./SelectDeliveryCompany.vue"
import SelectWorkerStatus from "./SelectWorkerStatus.vue"
import SelectStoragePlace from "./SelectStoragePlace.vue"
//
const props = defineProps({
  mailboxes: Array,
  deliveryCompanies: Array,
  storagePlaces: Array,
  mailTypes: Array,
  formValue: FormValue
})
const emit = defineEmits(["next"])
const next = () => {
  emit("next")
}

const nextWithValidate = () => {
  if (props.formValue.valid()) {
    next()
  }
}

</script>

<style scoped>
</style>
