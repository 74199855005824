<template>
  <div>
    <SelectDeliveryCompanyModal
        v-if="isShowSelectDeliveryCompanyModal"
        @close="closeSelectDeliveryCompanyModal"
        @select-item="selectItem"
        :delivery-companies="deliveryCompanies"
    />
    <div class="field" @click="openSelectDeliveryCompanyModal">
      <div class="select-list">
        <div class="display-value" :class="emptyValueClasses">{{deliveryCompanyLabel}}</div>
        <div v-show="formValue.showError('deliveryCompany')" class="error">選択してください</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, defineProps, ref} from "vue";
import SelectDeliveryCompanyModal from "./SelectDeliveryCompanyModal.vue"
import {FormValue} from "./libs/form_value";
const props = defineProps({
  deliveryCompanies: Array,
  formValue: FormValue
})

const isShowSelectDeliveryCompanyModal = ref(false)
const openSelectDeliveryCompanyModal = () => {
  isShowSelectDeliveryCompanyModal.value = true
}
const closeSelectDeliveryCompanyModal = () => {
  isShowSelectDeliveryCompanyModal.value = false
}

const selectedItem = computed(() => {
  return props.formValue.deliveryCompany
})

const deliveryCompanyLabel = computed(() => {
  if(!selectedItem.value) return "配送会社"
  return selectedItem.value.name
})

const emptyValueClasses = computed(() => {
  if(!selectedItem.value) return ["value-empty"]
  return []
})

const selectItem = (deliveryCompany) => {
  props.formValue.updateAttribute("deliveryCompany", deliveryCompany)
}
</script>

<style scoped>
</style>
