import {ref, computed} from "vue"
import {mailTypes} from "./mail_types";
import {workerStatuses} from "./worker_statuses";

export const confirmFormSupporter = (formValue) => {

  const images = computed(() => {
    return formValue.images
  })

  const mailboxLabel = computed(() => {
    return formValue.mailbox?.name
  })

  const autoScanLabel = computed(() => {
    return formValue.mailbox?.auto_scan ? "自動スキャンON" : "自動スキャンOFF"
  })

  const mailTypeLabel = computed(() => {
    if(!formValue.mailType) return ""
    return mailTypes[formValue.mailType]
  })

  const fromLabel = computed(() => {
    return formValue.from
  })

  const depthLabel = computed(() => {
    if(!formValue.depth) return ""
    return `${formValue.depth}mm`
  })

  const weightLabel = computed(() => {
    if(!formValue.width) return ""
    return `${formValue.width}mg`
  })

  const scanEnabledLabel = computed(() => {
    if(!formValue.workerStatus) return ""
    return workerStatuses[formValue.workerStatus]
  })

  const deliveryCompanyLabel = computed(() => {
    return formValue.deliveryCompany?.name
  })

  const storagePlaceLabel = computed(() => {
    return formValue.storagePlace?.name
  })

  const canSubmit = computed(() => {
    return formValue.canSubmit
  })

  const submitLabel = computed(() => {
    return formValue.canSubmit ? "登録する" : "送信中"
  })

  return {
    images,
    mailboxLabel,
    autoScanLabel,
    mailTypeLabel,
    fromLabel,
    depthLabel,
    weightLabel,
    scanEnabledLabel,
    deliveryCompanyLabel,
    storagePlaceLabel,
    canSubmit,
    submitLabel
  }
}
