import $ from 'jquery'

class TransferTaskRegister {
  constructor(args = {}) {
    this.submitId = args["submitId"] || "#submit"
    this.trackNoId = args["trackNoId"] || "#track-no"
    this.imagesId = args["imagesId"] || "#images"
    this.imagePreviewsId = args["imagePreviewsId"] || "#image-previews"
  }

  get submit() {
    return $(this.submitId)
  }

  get trackNo() {
    return $(this.trackNoId)
  }

  get images() {
    return $(this.imagesId)
  }

  get imagePreviews() {
    return $(this.imagePreviewsId)
  }

  changeUploadFiles(target) {
    this.imagePreviews.empty();
    [...target.files].forEach(fileData => {
      if (!fileData) return
      const reader = new FileReader();
      reader.onload = () => {
        const li = $('<li></li>')
        const img = $('<img style="max-width: 200px; max-height: 200px;"/>');
        img.attr("src", reader.result);
        li.append(img)
        this.imagePreviews.append(li);
      }
      reader.readAsDataURL(fileData);
    })
    this.checkInputs()
  }

  changeTrackNo() {
    this.checkInputs()
  }

  checkInputs() {
    const trackNo = this.trackNo.val()
    const files = this.images.prop("files")
    const imagesNum = files.length
    if (trackNo.length === 0 || imagesNum === 0) {
      this.submit.prop("disabled", true)
    } else {
      this.submit.prop("disabled", false)
    }
  }
}

window.TransferTaskRegister = TransferTaskRegister
