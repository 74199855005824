<template>
  <div class="image" v-if="isShow">
    <div class="number">{{ no }}</div>
    <img
        :src="imageSrc">
    <div v-if="editable" class="destroy" @click="remove()">x</div>
  </div>
</template>

<script setup>
import {defineEmits, defineProps, computed, ref, onMounted} from "vue"

const props = defineProps({
  image: File,
  idx: Number,
  editable: Boolean,
})
const emit = defineEmits(["remove"])
const imageSrc = ref("")
const remove = () => {
  emit("remove", props.idx)
}
const no = computed(() => {
  return props.idx + 1
})

const isShow = computed(() => {
  return imageSrc.value !== ""
})

onMounted(() => {
  const reader = new FileReader();
  reader.addEventListener("load", () => {
    imageSrc.value = reader.result
  }, false);
  reader.readAsDataURL(props.image);
})
</script>

<style scoped>
</style>
