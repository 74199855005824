import {ref} from "vue"
import axios from "axios";
const API_URL = "/api/v1/delivery_companies"
export const deliveryCompanies = ref([])
export const fetch = () => {
  axios
    .get(API_URL)
    .then(response => {
      deliveryCompanies.value = response.data
    })
    .catch(err => {
      console.log(err)
    })
}
