<template>
  <div>
    <div class="mt-4 text-end me-3"><a @click="prev">郵便情報編集</a></div>
    <div class="fields">
      <div class="field d-flex justify-content-between">
        <div class="label">郵便箱</div>
        <div class="value">{{ mailboxLabel }}</div>
      </div>
    </div>
    <div class="fields">
      <label class="field">
        <input type="file" ref="photoFile" accept="image/*" @change="selectFile"/>フォトライブラリー
      </label>
      <label class="field">
        <input type="file" ref="cameraFile" placeholder="撮影する" accept="image/*" capture="" @change="selectFile"/>
        撮影する
      </label>
    </div>
    <div class="images">
      <PreviewImage v-for="(image, idx) in images" :key="`${idx}-${image.name}`" :idx="idx" :image="image" :editable="true" @remove="removeImage"/>
    </div>
    <div class="field mt-4 text-end me-3">
      <button class="btn btn-primary" type="button" @click="next">つぎへ</button>
    </div>
  </div>
</template>

<script setup>
import {defineEmits, defineProps, computed, ref} from "vue"
import {FormValue} from "./libs/form_value";
import PreviewImage from "./PreviewImage.vue"

const props = defineProps({
  formValue: FormValue
})
const emit = defineEmits(["prev", "next"])
const prev = () => {
  emit("prev")
}
const next = () => {
  emit("next")
}

const photoFile = ref()
const cameraFile = ref()

const mailboxLabel = computed(() => {
  return props.formValue.mailbox?.name
})

const selectFile = (e) => {
  const image = e.target.files[0]
  if (!image) return
  props.formValue.addImage(image)
  // MEMO: 次にまた同じファイルを選択できるようにフォームにセットされているファイルをクリアしておく
  clearFiles()
}

const clearFiles = () => {
  photoFile.value.value = ""
  cameraFile.value.value = ""
}

const images = computed(() => {
  return props.formValue.images
})

const removeImage = (idx) => {
  props.formValue.removeImage(idx)
}
</script>

<style scoped>
</style>
