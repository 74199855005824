<template>
  <SelectModalBase @close="close" title="保管場所">
    <div class="search">
      <input type="number" v-model="searchWord" />
    </div>
    <ul class="values">
      <li
          v-for="storagePlace in filteredStoragePlaces"
          :key="storagePlace.id"
          @click="selectItem(storagePlace)">
        {{storagePlace.id}}: {{storagePlace.name}}
      </li>
    </ul>
  </SelectModalBase>
</template>

<script setup>
import {computed, defineEmits, defineProps, ref} from "vue"
import SelectModalBase from "./SelectModalBase.vue"
const props = defineProps({
  storagePlaces: Array
})
const emit = defineEmits(["close", "select-item"])
const close = () => {
  emit("close")
}
const selectItem = (val) => {
  emit("select-item", val)
  close()
}

const searchWord = ref("")
const filteredStoragePlaces = computed(() => {
  if(searchWord.value === "") return props.storagePlaces
  return props.storagePlaces.filter((storagePlace) => {
    return String(storagePlace.name).startsWith(searchWord.value)
  })
})
</script>

<style scoped>
</style>
