<template>
  <div>
    <SelectWorkerStatusModal
        v-if="isShowSelectScanEnabledModal"
        @close="closeSelectScanEnabledModal"
        @select-item="selectItem"
    />
    <div class="field" @click="openSelectScanEnabledModal">
      <div class="select-list">
        <div class="display-value" :class="emptyValueClasses">{{workerStatusLabel}}</div>
        <div v-show="formValue.showError('workerStatus')" class="error">選択してください</div>
      </div>
    </div>
  </div>
</template>

<script setup>

import {computed, defineProps, ref} from "vue";
import SelectWorkerStatusModal from "./SelectWorkerStatusModal.vue"
import {workerStatuses} from "./libs/worker_statuses";
import {FormValue} from "./libs/form_value";

const props = defineProps({
  formValue: FormValue
})
const isShowSelectScanEnabledModal = ref(false)
const openSelectScanEnabledModal = () => {
  isShowSelectScanEnabledModal.value = true
}
const closeSelectScanEnabledModal = () => {
  isShowSelectScanEnabledModal.value = false
}

const selectedItem = computed(() => {
  return props.formValue.workerStatus
})

const workerStatusLabel = computed(() => {
  if (!selectedItem.value) return "スキャン可否"
  return workerStatuses[selectedItem.value]
})

const emptyValueClasses = computed(() => {
  if (!selectedItem.value) return ["value-empty"]
  return []
})

const selectItem = (workerStatus) => {
  props.formValue.updateAttribute("workerStatus", workerStatus)
}
</script>

<style scoped>
</style>
