<template>
  <div>
    <SelectMailboxModal
        v-if="isShowSelectMailboxModal"
        @close="closeSelectMailboxModal"
        :mailboxes="mailboxes"
        @select-item="selectItem"
    />
    <div class="field" @click="openSelectMailboxModal">
      <div class="select-list">
        <div class="display-value" :class="emptyValueClasses">{{ mailboxLabel }}</div>
        <div v-show="formValue.showError('mailbox')" class="error">選択してください</div>
      </div>
      <div id="mailbox-info">
        <span class="me-3 room-no" :class="emptyValueClasses">{{ roomNoLabel }}</span>
        <span class="auto-scan" :class="emptyValueClasses">{{ autoScanLabel }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import {defineEmits, defineProps, ref, computed} from "vue"
import SelectMailboxModal from "./SelectMailboxModal.vue"
import {FormValue} from "./libs/form_value";

const props = defineProps({
  mailboxes: Array,
  formValue: FormValue
})
const emit = defineEmits(["close"])
const isShowSelectMailboxModal = ref(false)
const openSelectMailboxModal = () => {
  isShowSelectMailboxModal.value = true
}
const closeSelectMailboxModal = () => {
  isShowSelectMailboxModal.value = false
}
const selectItem = (mailbox) => {
  props.formValue.updateAttribute("mailbox", mailbox)
}

const selectedItem = computed(() => {
  return props.formValue.mailbox
})

const mailboxLabel = computed(() => {
  if(!selectedItem.value) return "郵便箱を選択"
  return selectedItem.value.name
})
const emptyValueClasses = computed(() => {
  if(!selectedItem.value) return ["value-empty"]
  return []
})
const roomNoLabel = computed(() => {
  if(!selectedItem.value) return "号室"
  return `${selectedItem.value.room_no}号室`
})
const autoScanLabel = computed(() => {
  if(!selectedItem.value) return "スキャン設定"
  const tmp = selectedItem.value.auto_scan ? "ON" : "OFF"
  return `自動スキャン: ${tmp}`
})
</script>

<style scoped>
</style>
